:root {
  --primary: #3f51b5;
  --secondary: #6b6e74;
}

.pointer {
  cursor: pointer;
}

body {
  font-size: 0.875rem;
}

.ck-drawer {
  min-width: 240px;
}

.ckn_toolbar {
  background-color: black;
}

.cdk_logo {
  padding: 1.2rem;
  width: 146px;
  height: 25px;
}

.ckn_list-menu svg {
  padding-bottom: 5px;
  padding-right: 5px;
}